import accessToken from "../../helpers/jwt-token-access/accessToken"
import { useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import baseURL from "../../utils"
import { Link } from "react-router-dom"
import { FaChevronLeft } from "react-icons/fa"

const CreateSweepers = () => {
  const [loading, setLoading] = useState(true)
  const [disableButton, setDisableButton] = useState(false)
  const [details, setDetails] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    centerId: "",
  })
  const [centers, setCenters] = useState([])
  const [errors, setErrors] = useState({})

  // fetch center
  useEffect(() => {
    const fetchData = async () => {
      try {
        const options = {
          method: "GET",
          headers: new Headers({ Authorization: accessToken }),
        }
        const response = await fetch(`${baseURL}/api/v1/admin/centers?paginate=0`, options)
        const centers = await response.json()
        setCenters(centers.data)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        console.log(error)
        toast.error(error.message)
      }
    }

    fetchData()
  }, [])

  // Create Sweeper
  const handleSubmit = async e => {
    e.preventDefault()
    // do stuff
    setDisableButton(true)
    setErrors({})
    try {
      const options = {
        method: "POST",
        headers: new Headers({
          Authorization: accessToken,
          "Content-Type": "application/json",
          accept: "application/json",
        }),
        body: JSON.stringify({
          first_name: details.first_name,
          last_name: details.last_name,
          phone: details.phone,
          email: details.email,
          center_id: details.centerId,
        }),
      }
      const response = await fetch(`${baseURL}/api/v1/admin/sweepers`, options)
      const data = await response.json()

      if (data.errors) {
        console.log(data.errors)
        toast.error(data["message"])
        setDisableButton(false)
        setErrors(data.errors)
      } else {
        toast.success(data["message"])
        setTimeout(() => {
          window.location.href = "/sweepers"
        }, 3000)
      }
    } catch (error) {
      // do what
      console.log(error)
      setDisableButton(false)
    }
  }

  return (
    <section className="mt-5">
      <ToastContainer />
      <div className="container">
        <div className="row my-5 d-flex justify-content-center">
          <div className="col-md-11 mt-5">
            <div className="card">
              <div className="card-body">
                <div
                  className="my-4 mx-lg-5 d-flex justify-content-center align-items-center"
                  style={{
                    gap: "4px",
                    padding: "7px 18px 7px 12px",
                    width: "76px",
                    borderRadius: "32px",
                    border: "1px solid #F0F0F0",
                    background: "#FFF",
                    boxShadow: "1px 1px 4px 0px rgba(0, 0, 0, 0.04)",
                    marginBottom: "8px",
                  }}
                >
                  <Link
                    to="/sweepers"
                    className="text-black d-flex align-items-center"
                  >
                    <FaChevronLeft size={16} />
                    <div
                      style={{
                        color: "#000",
                        fontSize: "14px",
                        fontWeight: "600",
                        marginLeft: "4px",
                      }}
                    >
                      Back
                    </div>
                  </Link>
                </div>
                <h3 className="text-green mx-lg-5">Create Sweeper</h3>
                <div className="row mx-lg-5">
                  <div className="col-md-12">
                    <form onSubmit={handleSubmit}>
                      <div className="row mt-2">
                        <h6 className="text-orange">Sweeper Details</h6>
                        <hr />
                        <div className="col-md-6 mb-4">
                          <label
                            htmlFor="firstName"
                            className="fw-bold text-green"
                          >
                            First Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter sweeper name"
                            value={details.first_name}
                            onChange={e =>
                              setDetails({
                                ...details,
                                first_name: e.target.value,
                              })
                            }
                            required
                          />
                          <small className="text-danger">
                            {errors?.first_name}
                          </small>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label
                            htmlFor="lastName"
                            className="fw-bold text-green"
                          >
                            Last Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter sweeper name"
                            value={details.last_name}
                            onChange={e =>
                              setDetails({
                                ...details,
                                last_name: e.target.value,
                              })
                            }
                            required
                          />
                          <small className="text-danger">
                            {errors?.last_name}
                          </small>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label
                            htmlFor="sweeperName"
                            className="fw-bold text-green"
                          >
                            Email
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter sweeper email"
                            value={details.email}
                            onChange={e =>
                              setDetails({
                                ...details,
                                email: e.target.value,
                              })
                            }
                            required
                          />
                          <small className="text-danger">{errors?.email}</small>
                        </div>
                        <div className="col-md-6 mb-4">
                          <label
                            htmlFor="sweeperName"
                            className="fw-bold text-green"
                          >
                            Phone
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter phone"
                            value={details.phone}
                            onChange={e =>
                              setDetails({
                                ...details,
                                phone: e.target.value,
                              })
                            }
                            required
                          />
                          <small className="text-danger">{errors?.phone}</small>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <h6 className="text-orange">Center</h6>
                        <hr />
                        <div className="row mt-4">
                          <div className="col-md-12">
                            <label>Select Center</label>
                            <select
                              className="form-select form-control shadow-none"
                              required
                              value={details.centerId}
                              onChange={e => {
                                setDetails({
                                  ...details,
                                  centerId: e.target.value,
                                })
                              }}
                            >
                              <option value="">--Select---</option>
                              {centers?.length > 0 ? (
                                centers.map(center => {
                                  const { uuid, name, location } = center
                                  return (
                                    <option key={uuid} value={uuid}>
                                      {name} - {location?.name || "No location"}
                                    </option>
                                  )
                                })
                              ) : (
                                <option disabled>No centers available</option>
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <button
                          type="submit"
                          className="send mt-4"
                          disabled={disableButton}
                        >
                          {disableButton
                            ? "Creating Sweeper..."
                            : "Create Sweeper"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CreateSweepers
